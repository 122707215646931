<template>
  <div class="custom-container" v-if="$route.meta.showParent">
    <el-row class="page-header align_center mb15" type="flex">
      <el-col :span="20" class="page-header-left">
        <el-row type="flex" class="align_center">
          <!-- 查询表单 -->
          <el-form
            ref="form"
            :inline="true"
            label-min-width="49px"
            :model="form"
            size="small"
            @submit.native.prevent
          >
            <el-form-item>
              <el-input
                style="width: 120px"
                v-model="form.lotNo"
                placeholder="入库批次"
                clearable
                @keyup.enter.native="getList"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                style="width: 120px"
                v-model="form.docNo"
                placeholder="单据号"
                clearable
                @keyup.enter.native="getList"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                style="width: 120px"
                v-model="form.ssociatedDocNo"
                placeholder="关联单据号"
                clearable
                @keyup.enter.native="getList"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                style="width: 140px"
                v-model="form.product"
                placeholder="产品编码/名称"
                clearable
                @keyup.enter.native="getList"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                style="width: 150px"
                v-model="form.warehouseType"
                placeholder="单据类型"
                clearable
              >
                <el-option
                  v-for="(item, index) in warehouseTypeOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                style="width: 150px"
                v-model="form.warehouseId"
                placeholder="仓库"
                clearable
              >
                <el-option
                  v-for="item in warehouseOptions"
                  :key="item.warehouseId"
                  :label="item.name"
                  :value="item.warehouseId"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="area">
              <el-select
                style="width: 120px"
                v-model="form.area"
                placeholder="区域"
                clearable
              >
                <el-option
                  v-for="(item, index) in areaList"
                  :label="item.areaName"
                  :value="item.areaName"
                  :key="index"
                />
              </el-select>
            </el-form-item>
            <!-- 操作时间 -->
            <el-form-item prop="dateArray">
              <el-date-picker
                v-model="dateArray"
                align="right"
                type="daterange"
                style="width: 240px"
                value-format="yyyy-MM-dd"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              ></el-date-picker>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="onReset">重置</el-button>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row type="flex" justify="end">
          <!-- 自定义展示列 -->
          <el-popover
            ref="showOption"
            popper-class="custom-table-checkbox"
            trigger="hover"
          >
            <div :list="columns">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="item + index"
                v-model="item.isShow"
                :disabled="item.checkable === false"
                :label="item.label"
              >
                {{ item.label }}
              </el-checkbox>
            </div>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button
                type="primary"
                class="ml10"
                size="mini"
                plain
                @click="operateSave"
              >
                保存
              </el-button>
            </div>
            <template #reference>
              <el-button plain type="primary">自定义显示</el-button>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>
    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :total="total"
      :table-data="tableData"
      :extraHeight="0"
      :pagination="true"
      @query="getList"
      :key="customTableKey"
      class="table-box"
    >
      <el-table-column
        label="操作时间"
        min-width="120"
        v-if="columns[0].isShow"
      >
        <div slot-scope="scope">
          {{ utils.cutDate(scope.row.createTime) }}
        </div>
      </el-table-column>
      <el-table-column
        v-if="columns[1].isShow"
        prop="skuId"
        label="SKU ID"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.skuId || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        label="核算维度"
        min-width="120"
        v-if="columns[2].isShow"
      >
        <div slot-scope="scope">
          {{
            scope.row.accountRule == 1
              ? '分仓核算'
              : scope.row.accountRule == 2
              ? '总仓核算'
              : '--'
          }}
        </div>
      </el-table-column>

      <el-table-column
        label="计价方式"
        min-width="120"
        v-if="columns[3].isShow"
      >
        <div slot-scope="scope">
          {{
            scope.row.priceType == 1
              ? '批次计价法'
              : scope.row.priceType == 2
              ? '移动加权'
              : '--'
          }}
        </div>
      </el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        show-overflow-tooltip
        min-width="160"
        v-if="columns[4].isShow"
      >
        <template slot-scope="scope">
          {{ scope.row.productName || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns[5].isShow"
        prop="productSpec"
        label="英文规格"
        min-width="180"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ $hasChineseChar(scope.row.productSpec) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns[6].isShow"
        prop="productSpecCn"
        label="中文规格"
        min-width="180"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.productSpecCn || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns[7].isShow"
        prop="warehouseName"
        label="所属仓库"
        min-width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.warehouseName || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns[8].isShow"
        prop="area"
        label="所属地区"
        min-width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.area || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns[9].isShow"
        prop="warehouseType"
        label="单据类型"
        min-width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.warehouseType || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns[10].isShow"
        prop="docNo"
        min-width="120"
        show-overflow-tooltip
      >
        <template slot="header">
          <span style="margin-right: 5px">单据号</span>
          <el-tooltip placement="bottom">
            <div slot="content">
              <p>单据类型为采购入库、退货入库、其它入库，单据号为入库单号；</p>
              <p>单据类型为销售出库、其它出库，单据号为出库单号；</p>
              <p>单据类型为入库成本调整、出库成本调整，单据号为调整单号；</p>
              <p>单据类型为盘盈入库，单据号为入库单号；</p>
              <p>单据类型为盘亏出库，单据号为出库单号；</p>
            </div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          {{ scope.row.docNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns[11].isShow"
        prop="ssociatedDocNo"
        min-width="120"
        show-overflow-tooltip
      >
        <template slot="header">
          <span style="margin-right: 5px">关联单据号</span>
          <el-tooltip placement="bottom">
            <div slot="content">
              <p>单据类型为采购入库，关联单据号为采购单号；</p>
              <p>单据类型为退货入库，关联单据号为销售单号；</p>
              <p>单据类型为其它入库，关联单据号为空；</p>
              <p>单据类型为销售出库，关联单据号为销售单号；</p>
              <p>单据类型为其它出库，关联单据号为空；</p>
              <p>
                单据类型为入库成本调整，其它入库类型关联单据号为--，采购入库关联单据号为采购单号；
              </p>
              <p>退货入库关联单据号为销售单号；</p>
              <p>单据类型为出库成本调整，销售出库关联单据号为销售单号；</p>
              <p>其它出库关联单据号为--；</p>
              <p>单据类型为盘亏出库、盘盈入库，关联单据号为盘点单号。</p>
            </div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          {{ scope.row.ssociatedDocNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns[12].isShow"
        prop="lotNo"
        label="入库批次"
        min-width="140"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.lotNo || '--' }}
        </template>
      </el-table-column>

      <!-- 入库 -->
      <el-table-column
        v-if="columns[13].isShow || columns[14].isShow || columns[15].isShow"
        label="入库"
      >
        <el-table-column
          v-if="columns[13].isShow"
          prop="warehouseInNum"
          label="入库数量"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseInNum || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[14].isShow"
          label="入库单价"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseInPrice > 0
                ? utils.moneyFormat(scope.row.warehouseInPrice)
                : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[15].isShow"
          label="入库成本"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseInTotalPrice > 0
                ? utils.moneyFormat(scope.row.warehouseInTotalPrice)
                : '--'
            }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- 出库 -->
      <el-table-column
        v-if="columns[16].isShow || columns[17].isShow || columns[18].isShow"
        label="出库"
      >
        <el-table-column
          v-if="columns[16].isShow"
          prop="warehouseOutNum"
          label="出库数量"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseOutNum > 0 ? scope.row.warehouseOutNum : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[17].isShow"
          label="出库单价"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseOutPrice > 0
                ? utils.moneyFormat(scope.row.warehouseOutPrice)
                : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[18].isShow"
          label="出库成本"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseOutTotalPrice > 0
                ? utils.moneyFormat(scope.row.warehouseOutTotalPrice)
                : '--'
            }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- 费用跳转 -->
      <el-table-column v-if="columns[19].isShow" label="成本调整">
        <el-table-column
          v-if="columns[19].isShow"
          label="调整金额"
          min-width="120"
        >
          <template slot-scope="scope">
            <span :class="scope.row.entryType == 1 ? 'red' : ''">
              <span v-show="scope.row.entryType == 1" class="f_s_16">-</span>
              {{
                scope.row.adjustPrice > 0
                  ? utils.moneyFormat(scope.row.adjustPrice)
                  : '0'
              }}
            </span>
          </template>
        </el-table-column>
      </el-table-column>

      <!-- 期末 -->
      <el-table-column
        v-if="
          columns[23].isShow ||
          columns[20].isShow ||
          columns[21].isShow ||
          columns[22].isShow
        "
        label="期末库存"
      >
        <el-table-column
          v-if="columns[20].isShow"
          prop="finalNum"
          label="期末数量"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.finalNum || 0 }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[21].isShow"
          label="批次入库单价"
          min-width="120"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.priceType == 2">--</div>
            <div v-else>
              {{ utils.moneyFormat(scope.row.warehouseInLotPrice) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[22].isShow"
          prop="finalPrice"
          label="库存单价"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.finalPrice ? scope.row.finalPrice.toFixed(3) : 0 }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns[23].isShow"
          label="期末总价"
          min-width="120"
        >
          <template slot-scope="scope">
            <div :class="scope.row.finalTotalPrice < 0 ? 'red' : ''">
              {{ utils.moneyFormat(scope.row.finalTotalPrice) || 0 }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </ErpTable>
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ErpDraggable from 'vuedraggable'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import Utils from '@/utils/utils.js'
  import CustomCache from '@/utils/custom-cache'
  import { warehouseTypeList, tableColumns } from './helper/data'
  import {
    StockStatisticsInteractor,
    FinanceThemeSetting,
    StockInteractor,
    InboundSheet,
  } from '@/core'

  export default {
    name: 'stock-statistics',
    components: {
      ErpDraggable,
    },
    data() {
      return {
        form: {
          lotNo: '', //批次
          docNo: '', // 单据号
          ssociatedDocNo: '', //关联单据号
          product: '', // 产品编码、名称
          warehouseType: '', // 出库方式
          area: '', // 仓库区域
          startTime: '', // 开始时间
          endTime: '', // 结束时间
          warehouseId: '', //	入库仓库
        },
        dateArray: [],
        tableData: [],
        page: {
          pageLe: 10,
          pageNo: 1,
        },
        total: 0,
        userCustomizeColumnId: '', //自义定保存id
        warehouseTypeOptions: warehouseTypeList,
        columns: tableColumns,
        warehouseOptions: [],
        areaList: [],
        customTableKey: Math.random(),
      }
    },
    watch: {
      columns: {
        handler() {
          this.customTableKey = Math.random()
        },
        deep: true,
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    created() {
      this.getList()
      this.getColumsData()
      this.getWareHouseList()
      this.getArea()
    },

    methods: {
      // 获取区域
      async getArea() {
        this.areaList = await FinanceThemeSetting.allDeptAreaByTenant()
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(10, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },
      async getWareHouseList() {
        this.warehouseOptions =
          await StockStatisticsInteractor.getWarehouseListPage()
      },

      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 10,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 查询
      search() {
        this.page.pageNo = 1
        this.getList()
      },

      // 获取详情
      async getList() {
        let params = {
          ...this.page,
          ...this.form,
        }
        let res = await StockStatisticsInteractor.getListLotTrackPage(
          params,
          this.dateArray
        )
        this.tableData = res?.data
        this.total = res?.total
      },
      async getshippingLookOrderAuthority(code) {
        let response = await StockInteractor.shippingLookOrderAuthority({
          orderCode: code,
        })
        if (response.code === '000000' && response.data !== 1) {
          return this.$message.success('暂无查看权限')
        }
      },

      //跳转详情 不要删
      // goToPage(str, id) {
      //   if (str === 'sale') {
      //     //查看销售单是否有权限
      //     return StockInteractor.shippingLookOrderAuthority({
      //       orderCode: id,
      //     }).then((res) => {
      //       if (res && res.code === '000000' && res.data !== 1) {
      //         return this.$message.success('暂无查看权限')
      //       } else {
      //         navigateTo(this, {
      //           pageType: PAGE_TYPES.salesOrderDetail,
      //           orderNumber: id,
      //         })
      //       }
      //     })
      //   }
      //   if (str === 'purchase') {
      //     return InboundSheet.roleDetailForShow({ businessId: id }).then(
      //       (res) => {
      //         if (res && res.code === '000000' && res.data !== 1) {
      //           return this.$message.success('暂无查看权限')
      //         } else {
      //           navigateTo(this, {
      //             pageType: PAGE_TYPES.purchaseOrderDetail,
      //             orderNumber: id,
      //           })
      //         }
      //       }
      //     )
      //   }
      //   let routeData = ''
      //   if (str === 'inbound-sheet') {
      //     routeData = this.$router.resolve({
      //       path: '/stock-center/inbound-sheet-detail',
      //       query: {
      //         warehouseInId: id,
      //         noReturn: true,
      //       },
      //     })
      //   } else if (str === 'outbound-sheet') {
      //     routeData = this.$router.resolve({
      //       name: 'outbound-sheet-detail',
      //       query: {
      //         warehouseOutId: id,
      //         noReturn: true,
      //       },
      //     })
      //   }
      //   window.open(routeData.href, '_blank')
      // },

      // 重置
      onReset() {
        this.page.pageNo = 1
        this.dateArray = []
        for (const key in this.form) {
          this.form[key] = ''
        }

        this.getList()
      },

      // 导出数据  不要删
      // exportData() {
      //   const params = {
      //     ...this.page,
      //     ...this.form,
      //   }
      //   InboundSheet.warehouseStatisticsExport(params).then((res) => {
      //     var linkElement = document.createElement('a')
      //     var blob = new Blob([res])
      //     var url = window.URL.createObjectURL(blob)
      //     linkElement.setAttribute('href', url)
      //     const downloadDate = Utils.timeFormat(new Date())
      //     let fileName = `进销存统计-${downloadDate}`
      //     linkElement.setAttribute('download', `${fileName}.xls`)
      //     document.body.appendChild(linkElement)
      //     linkElement.click()
      //     URL.revokeObjectURL(linkElement.href) // 释放URL 对象
      //   })
      // },
    },
  }
</script>

<style scoped lang="scss">
  .table-box {
    /deep/.el-table th > .cell {
      text-align: center;
    }
    /deep/.el-table .cell {
      text-align: center;
    }
  }
</style>
